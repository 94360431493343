import React from "react";
import "./App.css";
import {
  BlindFlow,
  ConfirmFlowScreen,
  ErrorPaymentScreen,
  CheckPaymentScreen,
  OvertimeFinishScreen,
  OvertimeLostFoundScreen,
  OvertimeCancelScreen,
  AGBScreen,
  InfoEmailScreen,
  PutLuggageScreen,
  PickUpScreen,
  InformPutScreen,
} from "./screens";
import { Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <Routes>
      <Route path="/:locationCode" element={<BlindFlow />} />
      <Route path="/open/:openingCode" element={<PickUpScreen />} />
      <Route path="/overtime/success" element={<OvertimeFinishScreen />} />
      <Route path="/overtime/lostfound" element={<OvertimeLostFoundScreen />} />
      <Route path="/overtime/cancel" element={<OvertimeCancelScreen />} />
      <Route path="/agb" element={<AGBScreen />} />
      <Route path="/inform/put" element={<InformPutScreen />} />
      <Route
        path="/check/:deliveryCode/:locationCode"
        element={<CheckPaymentScreen />}
      />
      <Route
        path="/error-payment/:locationCode"
        element={<ErrorPaymentScreen />}
      />
      <Route path="/info-email" element={<InfoEmailScreen />} />
      <Route path="/put-package/:deliveryCode" element={<PutLuggageScreen />} />
      <Route path="/open-package/:deliveryCode" element={<PickUpScreen />} />
      <Route
        path="/confirm-flow/:locationCode"
        element={<ConfirmFlowScreen />}
      />
    </Routes>
  );
};

export default App;

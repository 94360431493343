import React from "react";
import "./OvertimeFinishScreen.css";

export const OvertimeFinishScreen = () => {
  return (
    <div className="container">
      <p>
        Bitte entnehmen Sie alle Gepäckstücke und schließen Sie das Fach. Das
        Fach ist im Anschluss wieder frei.
      </p>
    </div>
  );
};

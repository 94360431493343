import React, { useEffect, useState } from "react";
import "./BlindFlow.css";
import { ScanStep } from "../../steps/ScanStep";
import { ListingSizesStep } from "../../steps/ListingSizesStep";
import { IPriceSize } from "../../shared/interfaces/size";
import { HandicappedStep } from "../../steps/HandicappedStep";
import { IStorage, IStoragePrice } from "../../shared/interfaces/storage";
import { PriceStep } from "../../steps/PriceStep";
import { EmailStep } from "../../steps/EmailStep";
import { useLocation, useParams } from "react-router-dom";
import { InfoScanStep } from "../../steps/InfoScanStep";

enum EFlowStep {
  InfoScan = 1,
  Scan,
  ListingSizes,
  HandicappedStep,
  PriceStep,
  EmailStep,
  PaymentStep,
}
export const BlindFlow = () => {
  const params = useParams();
  const { state: navigationState } = useLocation();

  const [locationCode, setLocationCode] = useState("");

  const [activeStep, setActiveStep] = useState(EFlowStep.InfoScan);
  const [scannedLocationId, setScannedLocationId] = useState<number>();
  const [selectedSize, setSelectedSize] = useState<IPriceSize>();
  const [selectedStorage, setSelectedStorage] = useState<IStorage>();
  const [storagePrice, setStoragePrice] = useState<IStoragePrice>();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [isBlind, setIsBlind] = useState(false);

  useEffect(() => {
    if (params?.locationCode && !locationCode) {
      setLocationCode(params?.locationCode);
      setActiveStep(EFlowStep.Scan);
    }
  }, [params, locationCode]);

  useEffect(() => {
    if (scannedLocationId && navigationState?.listing) {
      setActiveStep(EFlowStep.ListingSizes);
      window.history.replaceState({}, document.title);
    }
  }, [scannedLocationId, navigationState]);

  const onNextStepHandler = () => {
    setActiveStep(activeStep + 1);
  };

  const on2StepBackHandler = () => {
    setActiveStep(activeStep - 2);
  };

  const renderContent = () => {
    switch (activeStep) {
      case EFlowStep.InfoScan:
        return <InfoScanStep onNextStep={onNextStepHandler} />;
      case EFlowStep.Scan:
        return (
          locationCode && (
            <ScanStep
              locationCode={locationCode}
              setScannedLocationId={setScannedLocationId}
              setIsBlind={setIsBlind}
              onNextStep={onNextStepHandler}
            />
          )
        );
      case EFlowStep.ListingSizes:
        return (
          <ListingSizesStep
            locationId={scannedLocationId}
            setSelectedSize={setSelectedSize}
            onNextStep={onNextStepHandler}
          />
        );
      case EFlowStep.HandicappedStep:
        return (
          <HandicappedStep
            locationId={scannedLocationId}
            selectedSize={selectedSize}
            setSelectedStorage={setSelectedStorage}
            onNextStep={onNextStepHandler}
          />
        );
      case EFlowStep.PriceStep:
        return (
          <PriceStep
            selectedSize={selectedSize}
            startDate={startDate}
            setStoragePrice={setStoragePrice}
            setStartDate={setStartDate}
            onSizesStepBack={on2StepBackHandler}
            onNextStep={onNextStepHandler}
          />
        );
      case EFlowStep.EmailStep:
        return (
          <EmailStep
            storagePrice={storagePrice}
            locationCode={locationCode}
            selectedStorage={selectedStorage}
            startDate={startDate}
            isBlindFlow={isBlind}
            onNextStep={onNextStepHandler}
          />
        );
      default:
        return;
    }
  };

  return <div className="blind-container">{renderContent()}</div>;
};

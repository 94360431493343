import React, { useEffect, useState } from "react";
import { locationService } from "../../services/location.service";
import { IPriceSize } from "../../shared/interfaces/size";
interface IProps {
  locationId: number | undefined;
  setSelectedSize: (size: IPriceSize) => void;
  onNextStep: () => void;
}
export const ListingSizesStep = (props: IProps) => {
  const { locationId, setSelectedSize, onNextStep } = props;
  const [locationSizes, setLocationSizes] = useState<IPriceSize[]>([]);

  const getSizes = (locationId: number) => {
    locationService.getPriceSizes(locationId).then((response) => {
      if (response) {
        setLocationSizes(response);
      }
    });
  };

  useEffect(() => {
    if (locationId) {
      getSizes(locationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectPriceSize = (size: IPriceSize) => {
    setSelectedSize(size);
    onNextStep();
  };
  return (
    <div
      className="container"
      style={{
        padding: 30,
      }}
    >
      <p>Bitte wählen Sie die Fachgröße</p>
      {locationSizes.map((size, index) => {
        return (
          <button
            key={index + size.sizeId.toString()}
            style={{ margin: 10 }}
            onClick={() => onSelectPriceSize(size)}
          >
            {size.matchingName} - {size.height}x{size.width}x{size.depth}cm
          </button>
        );
      })}
    </div>
  );
};

import React from "react";
// import { locationService } from "../../services/location.service";
import { IStorageOvertimeResponse } from "../../shared/interfaces/storage";

interface IProps {
  overPaymentData: IStorageOvertimeResponse;
}

export const OverPaymentStep = (props: IProps) => {
  const { overPaymentData } = props;

  const checkOvertimeHandler = () => {
    if (overPaymentData.checkoutURL) {
      window.open(overPaymentData.checkoutURL, "_self");
    }
  };

  return (
    <div className="container">
      <p>Sie haben das Gepäck nicht in der gebuchten Zeit abgeholt.</p>
      <p>Wollen Sie das Fach jetzt öffnen?</p>
      <button style={{ marginTop: 10 }} onClick={checkOvertimeHandler}>
        Bezalen
      </button>
    </div>
  );
};

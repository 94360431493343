import React from "react";

export const ConfirmFlowScreen = () => {
  return (
    <div className="container">
      <p style={{ color: "green" }}>Bezahlung erfolgreich</p>
      <br />
      <p>
        Bitte drücke den Startknopf auf dem
        <br />
        Paketkasten.
        <br />
      </p>
      <p>
        Sobald eine Verbindung aufgebaut wurde,
        <br />
        öffnet sich das Fach.
      </p>
      <p>
        Bei der Öffnung können Sie ein klicken
        <br />
        des Faches vernehmen.
      </p>
      <br />
      <p>Für hilfe wählen Sie bitte folgende Hotline.</p>
      <p>0800 100 550</p>
    </div>
  );
};

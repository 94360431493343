import React from "react";
import "./AGBScreen.css";

export const AGBScreen = () => {
  return (
    <div style={{ overflowY: "scroll" }}>
      <p>
        <b>Nutzungsbedingungen für Gepäckschließfächer</b>
      </p>
      <p>
        Die Schließfächer stehen Ihnen zum Einstellen Ihres Reisegepäcks in der
        Zeit von 00:00 Uhr bis 24:00 Uhr zur Verfügung. Beachten Sie bitte die
        Bedienungsanweisungen am Bildschirm.
      </p>
      <p>
        {" "}
        Der Vertrag über die Benützung eines Schließfaches unter Geltung der
        Benutzungsbedingungen für Gepäckschließfächer beginnt mit der
        erfolgreichen Bezahlung der gewählten Fachgröße für den gewählten
        Zeitraum und dem Öffnen des für die Einlage vorgesehenen Faches und
        endet mit dem erneuten Öffnen des Faches durch den Benutzer.{" "}
        <b>
          Achtung: die Bezahlung gilt für eine einmalige Nutzung. Sobald Sie das
          Schließfach öffnen, verliert der Code seine Gültigkeit. Mit der
          Bezahlung des jeweiligen Entgeltes mieten Sie das Schließfach für
          einen ausgewählten Zeitraum (Normalmietdauer).{" "}
        </b>
        Erfolgt innerhalb dieser Normalmietdauer keine Entnahme des Gepäcks,
        müssen Sie für die Mietdauerüberschreitung eine entsprechende
        Nachzahlung (siehe Bildschirmanzeige) leisten. Nach einer
        Mietdauerüberschreitung von 5 Tagen wird das Schließfach entleert und
        das Gepäck kostenpflichtig aufbewahrt. Nach Ablauf von weiteren 3
        Monaten werden die zurückgelassenen Gegenstände verwertet. Verschlossene
        Gepäckstücke werden nach Ablauf eines Monats nach Entnahme aus dem
        Schließfach geöffnet. In diesen Fällen wenden Sie sich an den Tamburi
        Kundendienst 0800 100 550.
      </p>
      <p>
        {" "}
        Nicht in ein Schließfach gelegt werden dürfen: Tiere, nicht
        ordnungsgemäß verpackte leicht verderbliche, übelriechende, giftige,
        radioaktive, explosions-, feuer- und sonstige gefährliche Gegenstände.{" "}
      </p>
      <p>
        Wertvolle Gegenstände dürfen bis zu einem Gesamtwert von 500 € pro
        Schließfach deponiert werden.{" "}
      </p>
      <p>
        Die Tamburi Betriebs GmbH ist berechtigt, Gepäckschließfächer jederzeit
        zu öffnen. Sie ist des Weiteren berechtigt, dem Benützer ein
        Gepäckschließfach zu entziehen, wenn er es entgegen diesen
        Benutzungsbedingungen oder der Bedienungsanweisung verwendet.
      </p>
      <p>
        {" "}
        Der Benützer haftet für alle Schäden, die er verursacht oder die durch
        sein Gepäck entstehen.{" "}
      </p>
      <p>
        Die Tamburi Betriebs GmbH haftet für die ordnungsgemäße Beschaffenheit
        des Gepäckschließfaches. Sie haftet nicht für das Abhandenkommen der in
        dem Schließfach gelagerten Gegenstände, es sei denn, es liegt ein
        Verschulden der Tamburi Betriebs GmbH vor.
      </p>{" "}
      -Bei Vorsatz und grober Fahrlässigkeit ersetzt die Tamburi Betriebs GmbH
      den nachgewiesenen Wert des in das Schließfach eingelegten Gepäcks bis
      maximal 500€ pro Schließfach.
      <p>
        {" "}
        Im Störfall wenden Sie sich bitte an die Tamburi Betriebs GmbH 0800 100
        550.
      </p>
      <p>
        {" "}
        <b>Terms and Conditions for Luggage Lockers</b>{" "}
      </p>
      <p>
        The lockers are available for storing your luggage from 12:00 AM to
        12:00 AM. Please refer to the operating instructions on the screen.
      </p>
      <p>
        {" "}
        The contract for the use of a locker under the terms and conditions for
        luggage lockers begins with the successful payment for the selected
        locker size and chosen period and the opening of the designated
        compartment for depositing the luggage, and ends with the user's
        reopening of the compartment.{" "}
        <b>
          Note: the payment is valid for a single use. Once you open the locker,
          the code becomes invalid. By making the respective payment, you are
          renting the locker for a selected period (standard rental duration).
        </b>{" "}
        If the luggage is not retrieved within this standard rental duration,
        you will be required to make an additional payment for exceeding the
        rental period (see on-screen display). After exceeding the rental period
        by 5 days, the locker will be emptied, and the luggage will be stored at
        an additional cost. After an additional 3 months, any remaining items
        will be disposed of. Sealed luggage will be opened one month after
        removal from the locker. In such cases, please contact Tamburi Customer
        Service at 0800 100 550.
      </p>
      <p>
        The following items are not permitted in a locker: animals, improperly
        packaged perishable items, foul-smelling, toxic, radioactive, explosive,
        flammable, or other dangerous items. Valuable items can be stored in a
        locker up to a total value of €500 per locker.{" "}
      </p>
      <p>
        Tamburi Betriebs GmbH is authorized to open luggage lockers at any time.
        Furthermore, they have the right to revoke a locker from the user if
        they violate these terms and conditions or the operating instructions.
        The user is liable for any damages they cause or that result from their
        luggage. Tamburi Betriebs GmbH is responsible for the proper condition
        of the luggage locker. They are not liable for the loss of items stored
        in the locker, unless Tamburi Betriebs GmbH is at fault.
      </p>
      <p>
        {" "}
        - In cases of intent and gross negligence, Tamburi Betriebs GmbH will
        reimburse the proven value of the luggage stored in the locker, up to a
        maximum of €500 per locker. In case of a malfunction, please contact
        Tamburi Betriebs GmbH at 0800 100 550.
      </p>
    </div>
  );
};

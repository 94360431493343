import React from "react";
import "./OvertimeCancelScreen.css";

export const OvertimeCancelScreen = () => {
  return (
    <div className="container">
      <p>Ihre Zahlung wurde storniert.</p>
      <p>Bitte versuche es erneut.</p>
    </div>
  );
};

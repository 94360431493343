import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { locationService } from "../../services/location.service";
import { useNavigate } from "react-router-dom";

export const PutLuggageScreen = () => {
  const [deliveryCode, setDeliveryCode] = useState<string>();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params?.deliveryCode && !deliveryCode) {
      setDeliveryCode(params?.deliveryCode);
    }
  }, [params, deliveryCode]);

  const putLuggage = () => {
    if (deliveryCode) {
      locationService
        .putStorage(deliveryCode)
        .then((response) => {
          if (response) {
            if (response) {
              navigate(`../inform/put`);
            } else {
              console.log("error");
            }
          }
        })
        .catch((err) => {
          // TODO show error
        });
    }
  };

  return (
    <div className="container">
      <p>
        Bitte drücken Sie den folgenden Button und danach den Start Button am
        Locker um das Fach zu öffnen.
      </p>
      <p>
        Sie hören das Öffnen des Faches 10-20 Sekunden nach drücken des Buttons
        durch ein Klicken.
      </p>
      <p>Nach dem Einlegen erhalten Sie eine E-Mail zur Öffnen des Fachs.</p>
      <button style={{ marginTop: 10 }} onClick={putLuggage}>
        Fach öffnen{" "}
      </button>
    </div>
  );
};

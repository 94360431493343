import React, { useEffect, useState } from "react";
import { locationService } from "../../services/location.service";
import { useNavigate, useParams } from "react-router-dom";
import { OverPaymentStep } from "../../steps/OverPaymentStep";
import { IStorageOvertimeResponse } from "../../shared/interfaces/storage";

export const PickUpScreen = () => {
  const params = useParams();
  const [openingCode, setOpeningCode] = useState("");
  const [overtime, setOvertime] = useState(false);
  const navigate = useNavigate();
  const [overPaymentData, setOverPaymentData] =
    useState<IStorageOvertimeResponse | null>(null);

  useEffect(() => {
    if (params?.deliveryCode && !openingCode) {
      setOpeningCode(params?.deliveryCode);
    }
  }, [params, openingCode]);

  const checkOvertimeHandler = () => {
    if (openingCode) {
      locationService
        .openStorage(openingCode)
        .then((response) => {
          if (response) {
            if (response.success) {
              navigate(`../overtime/success`);
            } else {
              setOverPaymentData(response);
              setOvertime(true);
            }
          }
        })
        .catch((err) => {
          // TODO show error
        });
    }
  };

  return !overPaymentData ? (
    <div className="container">
      <p>Bitte den Start Button am Locker drücken um das Fach zu öffnen.</p>
      <p>Sie hören das Öffnen durch das Klicken des Schlosses.</p>
      {!overtime && (
        <button style={{ marginTop: 10 }} onClick={checkOvertimeHandler}>
          Fach öffnen
        </button>
      )}
    </div>
  ) : (
    <>
      <OverPaymentStep overPaymentData={overPaymentData} />
    </>
  );
};

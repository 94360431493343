import axios from "axios";

export const createAxios = () => {
  const instance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  instance.interceptors.response.use(async (res) => {
    if (typeof res.data !== "boolean" && !res.data && res.data !== "") {
      return Promise.reject(res);
    } else {
      return res.data;
    }
  });

  return instance;
};

import React from "react";

export const InformPutScreen = () => {
  return (
    <div className="container">
      <p>
        Bitte drücken Sie den Startknopf am Bedienterminal des Schließfachs. Das
        Fach öffnet sich 10-20 Sekunden nach dem Drücken des Start Button am
        Locker. Sie hören das Öffnen des Faches durch das Klicken des Schlosses.
        Nach dem Einlegen des Gepäckstückes erhalten Sie eine E-Mail um das Fach
        wieder zu öffnen.
      </p>
    </div>
  );
};

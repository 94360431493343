import React from "react";
import "./OvertimeLostFoundScreen.css";

export const OvertimeLostFoundScreen = () => {
  return (
    <div className="container">
      <p>Ihr Gepäckstück ist bei der nächsten Lost & Found Stelle der ÖBB.</p>
      <p>
        Sie erhalten eine E-Mail mit Quittung von Tamburi. Damit erhalten Sie
        Ihr Gepäckstück an der Lost&Found Stelle.
      </p>
    </div>
  );
};

import React from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ErrorPaymentScreen = () => {
  const navigate = useNavigate();
  const { locationCode } = useParams();

  return (
    <div className="container">
      <p>Fehlerhafte Zahlung</p>
      <button onClick={() => navigate(`../${locationCode}`, { replace: true })}>
        Fang nochmal an
      </button>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { deliveryService } from "../../services/delivery.service";

export const CheckPaymentScreen = () => {
  const [deliveryCode, setDeliveryCode] = useState<string>();
  const [locationCode, setLocationCode] = useState<string>();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params?.deliveryCode && !deliveryCode) {
      setDeliveryCode(params?.deliveryCode);
    }
    if (params?.locationCode && !locationCode) {
      setLocationCode(params?.locationCode);
    }
  }, [params, deliveryCode, locationCode]);

  useEffect(() => {
    if (deliveryCode) {
      deliveryService.checkConfirmPayment(deliveryCode).then((response) => {
        if (response) {
          navigate(`../confirm-flow/${locationCode}`);
        } else {
          // not paid check what should we do
          navigate(`../error-payment/${locationCode}`);
        }
      });
    }
  }, [deliveryCode, locationCode, navigate]);

  return (
    <div className="container">
      <p>Payment check</p>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { locationService } from "../../services/location.service";

interface IProps {
  locationCode: string;
  setIsBlind: (blind: boolean) => void;
  setScannedLocationId: (locationId: number) => void;
  onNextStep: () => void;
}

export const ScanStep = (props: IProps) => {
  const { locationCode, setIsBlind, setScannedLocationId, onNextStep } = props;

  const [isSuccess, setSuccess] = useState(false);
  const [locationName, setLocationName] = useState("");

  const getLocationByCode = (locationCode: string) => {
    locationService
      .getLocation(locationCode)
      .then((response) => {
        if (response) {
          setSuccess(true);
          setScannedLocationId(response.locationId);
          setLocationName(response.locationName);
        } else {
          setSuccess(false);
        }
      })
      .catch(() => {
        setSuccess(false);
      });
  };

  const onNextFlowHandler = (isBlindFlow: boolean) => {
    setIsBlind(isBlindFlow);
    onNextStep();
  };

  useEffect(() => {
    getLocationByCode(locationCode);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      {isSuccess ? (
        <>
          <p style={{ color: "green" }}>Sie haben erfolgreich gescannt</p>
          <p>Mein Namen ist: {locationName}</p>
          <p>Hier buchen Sie einfach Ihr Schließfach</p>
          <button
            style={{ margin: 10 }}
            onClick={() => onNextFlowHandler(false)}
          >
            Fach buchen
          </button>
          <p>
            Blinde oder sehbehinderte Personen drücken einfach den Button
            "Barrierefreies buchen" um ein Schließfach zu buchen.
          </p>
          <button
            style={{ margin: 10 }}
            onClick={() => onNextFlowHandler(true)}
          >
            Barrierefreies buchen
          </button>
        </>
      ) : (
        <div>loading</div>
      )}
    </div>
  );
};

import React, { useState } from "react";
import { IPriceSize } from "../../shared/interfaces/size";
import { IStoragePrice } from "../../shared/interfaces/storage";
import "./PriceStep.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import deAt from "date-fns/locale/de-AT";
registerLocale("de-AT", deAt);

interface IProps {
  selectedSize: IPriceSize | undefined;
  startDate: Date;
  setStoragePrice: (storagePrice: IStoragePrice) => void;
  setStartDate: (newStartDate: Date) => void;
  onNextStep: () => void;
  onSizesStepBack: () => void;
}

const months = [
  "Januar",
  "Februar",
  "Marsch",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

export const PriceStep = (props: IProps) => {
  const {
    selectedSize,
    startDate,
    setStoragePrice,
    setStartDate,
    onNextStep,
    onSizesStepBack,
  } = props;

  const [selectedHours, setSelectedHours] = useState(-1);
  const [selectedPrice, setSelectedPrice] = useState(-1);
  const [isDateOpen, setIsDateOpen] = useState(false);

  const [checked, setChecked] = React.useState(false);

  const isPriceSelected = selectedHours !== -1 && selectedPrice !== -1;

  const onSelectPriceHandler = (hours: number, price?: number) => {
    setSelectedHours(hours);
    if (price) {
      setSelectedPrice(price);
    }
  };

  const clearSelection = () => {
    setSelectedHours(-1);
    setSelectedPrice(-1);
    onSizesStepBack();
  };

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date || new Date());
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    setIsDateOpen(!isDateOpen);
  };

  const confirmSelection = () => {
    const body: IStoragePrice = {
      selectedHours: selectedHours,
      calculatedPrice: selectedPrice,
      startDate: startDate,
    };
    setStoragePrice(body);
    if (checked) {
      onNextStep();
    }
  };

  return !isPriceSelected ? (
    <div className="container">
      <p>
        Fachgröße {selectedSize?.matchingName}
        <br />
        <b>
          {selectedSize?.height}x{selectedSize?.width}x{selectedSize?.depth}cm
        </b>
      </p>
      <button
        style={{ marginBottom: 10, marginTop: 10 }}
        onClick={() => onSelectPriceHandler(3, selectedSize?.threeHourPrice)}
      >
        3 Stunden {selectedSize?.threeHourPrice} €
      </button>
      <button
        onClick={() => onSelectPriceHandler(24, selectedSize?.twoFourHourPrice)}
      >
        24 Stunden {selectedSize?.twoFourHourPrice} €
      </button>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <p>
          Startzeit: {startDate.toLocaleDateString().replaceAll("/", ".")}{" "}
          {startDate.toTimeString().substring(0, 5)}
        </p>
        <button
          style={{ padding: "0.2rem", width: "70%", alignSelf: "center" }}
          className="example-custom-input"
          onClick={handleClick}
        >
          Startzeit ändern
        </button>
      </div>

      <div style={{ marginTop: 10 }}>
        {isDateOpen && (
          <DatePicker
            timeCaption="Zeit"
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 5,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{ padding: 0, width: 40, height: 40 }}
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {"<"}
                </button>
                <p>
                  {months[date.getMonth()]} {date.getFullYear()}
                </p>

                <button
                  style={{ padding: 0, width: 40, height: 40 }}
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {">"}
                </button>
              </div>
            )}
            locale={"de-AT"}
            timeFormat={"HH:mm"}
            showTimeSelect
            selected={startDate}
            onChange={handleStartDateChange}
            inline
          />
        )}
      </div>
    </div>
  ) : (
    <div className="container">
      <p>
        Sie wollen ein Fach mit der Größe {selectedSize?.matchingName}
        <br />
        <b>
          {selectedSize?.height}x{selectedSize?.width}x{selectedSize?.depth}cm
        </b>{" "}
        für {selectedHours} Stunden um {selectedPrice} €
        <br />
        buchen?
      </p>
      <div>
        <p style={{ display: "flex", alignItems: "center" }}>
          <a href="../agb" target="_blank">
            AGB
          </a>
          <input type="checkbox" checked={checked} onChange={handleChange} />
        </p>
      </div>
      {!checked && <p>Bitte akzeptieren Sie unsere AGB um fortzufahren.</p>}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <button onClick={confirmSelection} style={{ marginRight: 10 }}>
          Ja
        </button>
        <button onClick={clearSelection}>Nein</button>
      </div>
    </div>
  );
};

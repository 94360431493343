import React from "react";

interface IProps {
  onNextStep: () => void;
}

export const InfoScanStep = (props: IProps) => {
  const { onNextStep } = props;
  return (
    <div className="container">
      <p>
        Bitte scannen Sie den QR-Code, der sich auf dem Schließfach befindet
      </p>
      <button style={{ marginTop: 10 }} onClick={onNextStep}>
        Scan
      </button>
    </div>
  );
};

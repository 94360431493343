import React, { useState } from "react";
import { IPriceSize } from "../../shared/interfaces/size";
import { locationService } from "../../services/location.service";
import { IStorage, IStorageBody } from "../../shared/interfaces/storage";

interface IProps {
  locationId: number | undefined;
  selectedSize: IPriceSize | undefined;
  setSelectedStorage: (storage: IStorage) => void;
  onNextStep: () => void;
}

export const HandicappedStep = (props: IProps) => {
  const { locationId, selectedSize, setSelectedStorage, onNextStep } = props;
  const [isError, setError] = useState(false);

  const createStorage = (isHendicaped: boolean) => {
    if (!locationId || !selectedSize) {
      return;
    }

    const bodyRequest: IStorageBody = {
      locationId: locationId,
      // carrierId: 28, for dev
      carrierId: 7,
      sizeId: selectedSize.sizeId,
      handicapped: isHendicaped,
      resendCount: 0,
      resend: false,
    };
    locationService
      .createStorageRequest(bodyRequest)
      .then((response) => {
        if (response) {
          // save the response for later use
          setSelectedStorage(response);
          onNextStep();
        }
      })
      .catch((err) => {
        setError(true);
      });
  };
  return (
    <div className="container">
      <p>Benötigen Sie ein Schließfach unter 1,3 Meter Höhe?</p>
      {isError && (
        <p style={{ color: "red" }}>
          Kein Fach mit Größe X unter 1,3 Meter Höhe verfügbar.
        </p>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <button style={{ marginRight: 10 }} onClick={() => createStorage(true)}>
          Ja
        </button>
        <button onClick={() => createStorage(false)}>Nein</button>
      </div>
    </div>
  );
};

import React, { useState } from "react";
import {
  IStorage,
  IStorageConfirmBody,
  IStoragePrice,
} from "../../shared/interfaces/storage";
import "./EmailStep.css";
import { Constants } from "../../shared/utils/constants";
import { locationService } from "../../services/location.service";

interface IProps {
  onNextStep: () => void;
  storagePrice: IStoragePrice | undefined;
  selectedStorage: IStorage | undefined;
  startDate: Date;
  locationCode: string;
  isBlindFlow: boolean;
}

export const EmailStep = (props: IProps) => {
  const { onNextStep, storagePrice, startDate, selectedStorage, isBlindFlow } =
    props;
  const [email, setEmail] = useState("");
  const [validate, setValidate] = useState(false);
  const isEmailValid =
    !!email && Constants.RegularExpressions.Email.test(email);

  const onValidateEmail = async () => {
    if (!isEmailValid) {
      setValidate(true);
      // TODO: show error message not valid email
      return;
    }

    confirmStorage(email);
  };

  const confirmStorage = (email: string) => {
    if (
      !selectedStorage?.deliveryCode ||
      !storagePrice?.selectedHours ||
      !storagePrice?.calculatedPrice
    ) {
      // showing error not avalable
      return;
    }

    const localStartDate = `${
      startDate.toISOString().split("T")[0]
    }T${startDate.toLocaleTimeString()}`;

    const bodyRequest: IStorageConfirmBody = {
      deliveryCode: selectedStorage?.deliveryCode,
      email: email,
      startDate: localStartDate,
      selectedHours: storagePrice?.selectedHours,
      calculatedPrice: storagePrice?.calculatedPrice,
      redirectURL: `${process.env.REACT_APP_MOLLIE_REDIRECT}/info-email`,
      blind: isBlindFlow,
    };

    locationService
      .confirmStorage(bodyRequest)
      .then((response) => {
        if (response) {
          if (response.success) {
            window.open(response.webhookURL, "_self");
            onNextStep();
          } else {
            //error creating webhook
          }
        }
      })
      .catch((err) => {
        // showing error creating storage
      });
  };

  const handleChange = (event: any) => {
    setEmail(event.target.value);
  };

  return (
    <div className="container">
      <p>
        Geben Sie Ihre E-Mail-Adresse ein.
        <br />
        Nach Einlage des Gepäcks erhalten Sie
        <br />
        eine E-Mail mit Abhol-Link.
      </p>
      <input
        style={{ width: "95%", marginTop: 10 }}
        className={validate && !isEmailValid ? "error-input" : ""}
        autoCapitalize="none"
        placeholder="Ihre E-Mail Adresse"
        value={email}
        onChange={handleChange}
      />
      {validate && !isEmailValid && (
        <p style={{ color: "red" }}>Bitte geben Sie eine E-Mail-Adresse ein</p>
      )}
      <button style={{ marginTop: 10 }} onClick={onValidateEmail}>
        Bestätigen
      </button>
    </div>
  );
};

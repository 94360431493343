import { createAxios } from "../config/axios";
import { IQRLocation } from "../shared/interfaces/location";
import { IPriceSize } from "../shared/interfaces/size";
import {
  IStorage,
  IStorageBody,
  IStorageConfirm,
  IStorageConfirmBody,
  IStorageOvertimeResponse,
} from "../shared/interfaces/storage";

const getLocation = async (qrCode: string): Promise<IQRLocation> =>
  createAxios().get("remote_location/qr_code/" + qrCode);

const getPriceSizes = async (locationId: number): Promise<IPriceSize[]> =>
  createAxios().get("remote_location/price_list?locationId=" + locationId);

const createStorageRequest = async (body: IStorageBody): Promise<IStorage> =>
  createAxios().post("reservation/storage", body);

const openStorage = async (
  openingCode: string
): Promise<IStorageOvertimeResponse> =>
  createAxios().post(`remote_delivery/storage/open/${openingCode}`);

const putStorage = async (deliveryCode: string): Promise<Boolean> =>
  createAxios().post(`remote_delivery/storage/new/${deliveryCode}`);

const confirmStorage = async (
  body: IStorageConfirmBody
): Promise<IStorageConfirm> =>
  createAxios().post("reservation/storage/confirm/mobile", body);

export const locationService = {
  getLocation,
  getPriceSizes,
  createStorageRequest,
  confirmStorage,
  openStorage,
  putStorage,
};
